<template>
  <div class="grid items-center bg-grey-90 px-4 py-10 md:cols-2 lg:px-10">
    <div>
      <h2 class="elevate-title-3 c-grey-30">
        {{ title }}
      </h2>
      <ul class="mt-4 space-y-4">
        <li v-for="(item, i) in items" :key="i" class="w-fit">
          <base-link
            :to="item.mediaTarget.url"
            class="elevate-title-1 py-1"
            @focus="selectedItem = item"
            @mouseover="selectedItem = item"
          >
            <span
              v-style="{
                bgv: 'linear-gradient(to right, #cf780f, #cf780f 50%, #000 50%)',
              }"
              class="animate-text bg-$bgv <md:hidden"
            >
              {{ item.title }}
            </span>
            <span class="c-brand-2 md:hidden">
              {{ item.title }}
            </span>
          </base-link>
        </li>
      </ul>
    </div>
    <div class="grid place-content-center <md:hidden">
      <base-link
        v-for="(item, i) in items"
        :key="i"
        :to="item.mediaTarget.url"
        aria-hidden="true"
        tabindex="-1"
        class="grid-area-stack"
        :class="{ 'animate-fade-in-half': selectedItem.id === item.id }"
        :style="{ 'z-index': selectedItem.id === item.id ? 1 : 0, 'opacity': selectedItem.id === item.id ? 1 : 0.5 }"
      >
        <base-picture
          v-if="getMedia(item.media).images"
          v-bind="getMedia(item.media).images"
          fit="cover"
          class="w-full overflow-hidden rounded-lg"
        />
      </base-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import componentTypeConfig from './config'
import type { CollectionContent } from '#types/components/cms/collection'

const { content } = defineProps<{ content: CollectionContent<any> }>()

const { title } = content

const { getMedia } = useCms()

const { itemsProp } = componentTypeConfig[content.type!] || {}

const items = (itemsProp ? content[itemsProp] : content.items) || []

const selectedItem = ref(items.length ? items[0] : undefined)
</script>

<style scoped>
@keyframes fade-in-half {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in-half {
  animation: fade-in-half 0.3s linear 1;
}

.animate-text {
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.animate-text:hover {
  background-position: 0;
}
</style>
